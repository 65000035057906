@use '../settings/variable' as v;
@use '../settings/mixin' as m;
@use '../settings/path' as p;

.ic-arrow01 {
  width: 19px;
  height: 17px;
  background: url(p.$path + 'images/common/icon-arrow01.svg') no-repeat center/100%;
  display: inline-block;
  transition: 0.3s all;
  @include m.sp {
    width: 15px;
    height: 13px;
  }

  &.is-white {
    filter: brightness(0) invert(1);
  }

  &.is-left {
    transform: rotate(-90deg);
  }
}

.ic-arrow02 {
  width: 9px;
  height: 10px;
  background: url(p.$path + 'images/common/icon-arrow02.svg') no-repeat center/100%;
  display: inline-block;

  &.is-up {

  }
}
