@use "../settings/variable" as v;
@use "../settings/mixin" as m;
@use "../settings/path" as p;

.c-header {
  width: 100%;
  min-width: v.$base-width;
  position: fixed;
  background-color: rgba(#ffffff, 0.9);
  top: 0;
  left: 0;
  z-index: 90;
  @include m.sp {
    min-width: auto;
    transition: background-color 0.3s ease-in-out;

    &.is-nav-show {
      background-color: transparent;
    }
  }

  &__cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px 0 40px;
    height: 100px;
    border-bottom: 3px solid rgba($color: #558bb2, $alpha: 0.3);
    @include m.sp {
      border-bottom: 0;
      height: 55px;
      padding: 0;
    }
  }

  &__logo {
    position: relative;
    padding-right: 40px;
    z-index: 91;
    @include m.sp {
      max-width: 200px;
      padding-left: 20px;
    }
    &1 {
      width: 162px;
      @include m.sp {
        width: 88px;
      }
    }
    &2 {
      width: 258px;
      margin-left: 5px;
      margin-top: 12px;
      @include m.sp {
        margin-left: 2px;
        margin-top: 5px;
      }
    }
  }

  &__nav {
    position: relative;
    z-index: 90;
    padding-top: 50px;
    padding-right: 15px;
    @include m.sp {
      padding-top: 0;
      padding-right: 0;
    }
  }

  &__navToggle {
    display: none;
    @include m.sp {
      width: 55px;
      height: 55px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      background-color: v.$color1;
    }

    &.is-active span {
      transition: all 0.2s ease-in-out, transform 0.3s ease-in-out 0.2s;

      &:nth-child(1) {
        top: 0;
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        width: 0;
        opacity: 0;
      }

      &:nth-child(3) {
        bottom: 0;
        transform: rotate(-45deg);
      }
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      display: block;
      background-color: #ffffff;
      border-radius: 10px;
      width: 32px;
      height: 2px;
      transition: transform 0.3s ease-in-out, all 0.2s ease-in-out 0.3s;

      &:nth-child(1) {
        top: -18px;
      }

      &:nth-child(3) {
        bottom: -18px;
      }
    }
  }

  // .c-header__navBody .
  &__navBody {
    position: relative;
    @include m.pc {
      display: block !important;
    }
    @include m.sp {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      background-color: rgba(#ffffff, 0.9);
      padding: 74px 0 120px 0;
      display: none;
    }
  }

  &__navMenu {
    display: flex;
    width: 100%;
    @include m.sp {
      display: block;
    }
  }

  &__navItem {
    @include m.oswald;
    font-size: 1.6rem;
    line-height: 1;
    font-weight: 600;
    margin-left: 16px;
    text-align: center;
    position: relative;
    padding-bottom: 16px;
    cursor: pointer;
    @include m.sp {
      margin: 0 20px;
      font-size: 1.2rem;
      font-weight: bold;
      text-align: left;
      border-bottom: 1px solid v.$color1;
      padding-bottom: 0;

      &:first-child {
        border-top: 1px solid v.$color1;
      }
    }
    @include m.hover {
      .c-header__link {
        border-bottom-color: v.$color1;
      }
      .c-nav1 {
        opacity: 1;
        pointer-events: all;
      }
    }

    &.is-active .c-header__link {
      border-bottom-color: v.$color1;
    }

    &.is-sub {
      .c-header__link {
        @include m.sp {
          position: relative;
          width: 100%;
        }
        &::after {
          @include m.sp {
            content: "";
            width: 15px;
            height: 2px;
            background: v.$color1;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        &::before {
          @include m.sp {
            content: "";
            width: 2px;
            height: 15px;
            background: v.$color1;
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        &.is-active {
          &::before {
            @include m.sp {
              content: none;
            }
          }
        }
      }
    }
  }
  &__link {
    display: block;
    border-bottom: 2px solid transparent;
    text-decoration: none;
    white-space: nowrap;
    padding: 8px 0;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    @include m.sp {
      padding: 13px 0;
      border-bottom: 0;
      display: inline-block;
      margin: 0 auto;

      &:after {
        content: "";
        display: inline-block;
        width: 9px;
        height: 10px;
        background: url(p.$path + "images/common/icon-arrow02.svg") no-repeat center/cover;
        margin-left: -9px;
        position: relative;
        right: -19px;
      }
    }
  }
}

.c-nav1 {
  position: absolute;
  top: 100%;
  left: 0;
  width: 220px;
  background: rgba($color: #fff, $alpha: 0.9);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  @include m.sp {
    opacity: 1;
    display: none;
    position: relative;
    transition: none;
    width: 100%;
    background: none;
    pointer-events: all;
  }
  &__list {
    padding: 11px 14px;
    @include m.sp {
      padding: 0 14px 11px;
    }
  }
  &__item {
    text-align: left;
    &:not(:last-child) {
      margin-bottom: 11px;
    }
  }
  &__link {
    font-size: 1.6rem;
    line-height: 1.4;
    font-weight: 500;
    color: #000;
    position: relative;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    padding-right: 10px;
    @include m.sp {
      font-size: 1.2rem;
    }
    &::after {
      content: "";
      width: 10px;
      height: 12px;
      background: url(p.$path + "images/common/icon-arrow02.svg") no-repeat center/100%;
      position: absolute;
      top: 50%;
      right: -5px;
      transform: translateY(-50%);
      margin-top: 2px;
      @include m.sp {
        margin-top: 0;
      }
    }
    @include m.hover {
      opacity: 0.7;
    }
  }
  &.style1 {
    width: 190px;
    @include m.sp {
      width: 100%;
    }
  }
  &.style2 {
    width: 230px;
    @include m.sp {
      width: 100%;
    }
  }
  &.style3 {
    width: 280px;
    left: auto;
    right: 0;
    @include m.sp {
      width: 100%;
    }
  }
}
