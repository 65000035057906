@use "../settings/variable" as v;
@use "../settings/mixin" as m;
@use "../settings/path" as p;

.c-mv01 {
  position: relative;
  overflow: hidden;

  &__slide {
    display: flex;
  }

  &__item {
    width: 100%;
    flex-shrink: 0;
    position: relative;
  }

  &__img {
    position: relative;
    overflow: hidden;
    z-index: 1;
    height: 100%;
    @include m.sp {
      /*min-height: 258px;*/
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000000, 0.15);
    }
  }

  &__body {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 20px 55px 40px;
    z-index: 2;
    @include m.sp {
      padding: 0 20px 22px;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
    }
  }

  &__text1 {
    @include m.impact;
    color: rgba(#ffffff, 0.7);
    font-size: 9.5rem;
    line-height: 1;
    @include m.sp {
      font-size: v.vw(39);
      margin-bottom: 12px;
    }
  }

  &__text2 {
    @include m.sawarabi;
    color: rgba(#ffffff, 0.57);
    font-size: 5rem;
    line-height: 1.24;
    font-weight: bold;
    text-shadow: 0 3px 6px rgba(#000000, 0.16);
    @include m.sp {
      font-size: v.vw(16);
      line-height: 1.5625;
    }
  }

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background-color: #dddddd;
    opacity: 1 !important;
    margin: 0 7px !important;

    &.swiper-pagination-bullet-active {
      background-color: #626262;
    }
  }
}

.c-mv02 {
  background: url(p.$path + "images/common/mv01.jpg") no-repeat center/cover;
  margin-bottom: 45px;
  @include m.sp {
    margin-bottom: 40px;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 26px 0;
  }

  &__titleInner {
    position: relative;
    @include m.impact;
    font-size: 12rem;
    line-height: 1;
    color: rgba(#ffffff, 0.3);
    text-align: center;
    margin: -12px 0;
    pointer-events: none;
    white-space: nowrap;
    @include m.sp {
      font-size: 6rem;
      margin: -6px 0;
    }
  }

  &__titleJap {
    @include m.yugothic;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1.2;
    color: #ffffff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    @include m.sp {
      font-size: 1.8rem;
    }
  }

  &--style01 {
    background: url(p.$path + "images/common/mv03.jpg") no-repeat center/cover;
  }
}

/*------------------------------------------------*/
.c-mv03 {
  position: relative;
  height: 474px;

  &__img {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__txt {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    padding-top: 100px;
    display: flex;
    align-items: center;
    background: rgba($color: #000000, $alpha: 0.3);
    @include m.sp {
      padding-top: 55px;
    }
  }

  &__ttl {
    font-size: 3.2rem;
    line-height: 6.7rem;
    color: #fff;
    font-weight: bold;
    letter-spacing: 3px;
    @include m.sp {
      font-size: 2.4rem;
      line-height: 1.6;
      letter-spacing: 0;
    }
    @include m.mq(miniless) {
      font-size: 6vw;
    }
  }

  &__shape {
    shape-outside: polygon(-65px 0, 0 200px, 238px 300px);
    width: 300px;
    height: 250px;
    float: left;
    @include m.sp {
      height: 150px;
    }
    @include m.mq(miniless) {
      shape-outside: polygon(-35px 0, 0 200px, 238px 300px);
      height: 117px;
    }
  }
}

/*------------------------------------------------*/
.c-mv04 {
  height: calc((431 / 1080) * 100vw);
  overflow: hidden;
  position: relative;

  @include m.sp {
    height: 300px;
  }

  // .c-mv04__btn .
  &__btn {
    right: 51px;
    bottom: 45px;
    z-index: 1;
    position: absolute;
    padding: 5px 20px;
    border: 2px solid #fff;
    font-size: 1.8rem;
    font-weight: 700;
    display: inline-block;
    color: #fff;
  }

  &__video {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    #recruitVideo {
    }

    iframe {
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/*------------------------------------------------*/
.c-mv05 {
  width: 100%;
  position: relative;
  height: calc((578 / 1080) * 100vw);
  overflow: hidden;

  &__txt {
    position: absolute;
    left: 0;
    top: 41px;
    background: rgba(#ffffff, 0.8);
    color: #000000;
    z-index: 1;
    padding-left: 66px;
    padding-right: 30px;
    height: 67px;
    line-height: 1;
    font-size: 3.2rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    @include m.sp {
      font-size: 2rem;
      top: 20px;
      padding-left: 40px;
      padding-right: 20px;
      height: 45px;
    }
  }
}
