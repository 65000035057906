@use "../settings/variable" as v;
@use "../settings/mixin" as m;
@use "../settings/path" as p;

.c-box1 {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  &__txt {
    margin-right: 14px;
  }
  &__info {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 700;
    margin-bottom: 5px;
  }
  &__name {
    font-size: 2.4rem;
    line-height: 1;
    font-weight: bold;
    @include m.sp {
      font-size: 2rem;
    }
  }
}

.c-box2 {
  @include m.bg-linear;
  transition: all 0.3s ease-in-out;
  @include m.pc {
    display: flex;
  }
  @include m.sp {
    display: block;
  }
  @include m.hover {
    opacity: 0.8;
  }

  &__img {
    width: 499px;

    @include m.sp {
      width: 100%;
    }
  }

  // .c-box2__text .
  &__text {
    @include m.pc {
      padding-left: 4px;
    }

    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 500;
    color: #fff;
    @include m.sp {
      font-size: 1.4rem;
      line-height: 1.5;
    }
  }

  // .c-box2__content .
  &__content {
    padding: 71px 10px 30px 27px;
    flex: 1;
    @include m.sp {
      padding: 30px 10px;
    }
  }

  // .c-box2__title .
  &__title {
    margin-bottom: 13px;
    color: #fff;
    font-weight: 700;
    line-height: 1.5;
    font-size: 2.8rem;
    @include m.sp {
      font-size: 2rem;
    }
  }
}

.c-label01 {
  padding: 4px 7px 2px;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.5;
  display: inline-block;
  background-color: v.$color1;
  color: #fff;
  min-width: 68px;
  text-align: center;

  .is-small {
    font-size: 1.2rem;
  }
}

.c-label02 {
  font-size: 1.4rem;
  line-height: 1;
  font-weight: 700;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;

  // .c-label02__label .
  &__label {
    padding: 11px 21px 11px 35px;
    text-align: center;
    border: 1px solid #000;
    border-radius: 999px;
    overflow: hidden;
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 171px;

    // .c-label02__label:before .
    &:before {
      left: 9px;
      top: 50%;
      transform: translateY(-50%);
      content: "";
      position: absolute;
      width: 19px;
      height: 19px;
      border: 1px solid #000;
      border-radius: 50%;
      background-color: #fff;
    }

    &.is-bg1 {
      background-color: v.$color4;
    }

    &.is-bg2 {
      background-color: v.$color5;
    }
  }

  // .c-label02__text .
  &__text {
    margin-top: 10px;
  }
}

.c-accordion {
  $r: &;
  font-size: 2.4rem;
  font-weight: bold;
  border: 3px solid v.$color1;
  margin-bottom: 56px;
  @include m.sp {
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 20px;
  }
  &:last-child {
    margin-bottom: 0;
  }

  //.c-accordion__list .
  &__list {
    margin: 40px 0;
    counter-reset: accor-list;

    @include m.sp {
      margin: 16px 0;
    }

    &__item {
      display: flex;
      line-height: 4rem;
      @include m.sp {
        line-height: 1.4;
      }

      &:before {
        margin-right: 7px;
        counter-increment: accor-list;
        content: "(" counter(accor-list) ")";
        min-width: max-content;
      }
    }
  }

  // .c-accordion__title .
  &__title {
    padding: 21px 94px 17px;
    position: relative;
    cursor: pointer;
    @include m.sp {
      padding: 15px 35px;
    }

    // .c-accordion__title::after.
    &::after {
      content: "Q";
      position: absolute;
      left: 29px;
      top: 53%;
      transform: translate(0, -50%);
      color: v.$color1;
      font-size: 4.9rem;
      font-weight: bold;
      @include m.sp {
        font-size: 2.6rem;
        left: 10px;
        top: 15px;
        transform: translate(0, 0);
      }
    }
  }

  // .c-accordion__icon .
  &__icon {
    right: 23px;
    top: 50%;
    cursor: pointer;
    position: absolute;
    transform: translate(0, -50%);
    width: 49px;
    height: 49px;
    background: #e2ebf2;
    border-radius: 100%;
    @include m.sp {
      width: 20px;
      height: 20px;
      right: 10px;
    }
    &::after {
      content: "";
      width: 31px;
      height: 4px;
      background: v.$color1;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      @include m.sp {
        width: 14px;
        height: 2px;
      }
    }
    &::before {
      content: "";
      width: 4px;
      height: 31px;
      background: v.$color1;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      @include m.sp {
        width: 2px;
        height: 14px;
      }
    }
    &.is-active {
      &::before {
        content: none;
      }
    }
  }

  // .c-accordion__content .
  &__content {
    padding: 20px 30px 23px 94px;
    background: #e2ebf2;
    position: relative;
    border-top: 3px solid v.$color1;
    overflow: hidden;
    display: none;
    @include m.sp {
      padding: 15px 20px 23px 35px;
    }

    // .c-accordion__content::after .
    &::after {
      left: 29px;
      top: 22px;
      content: "A";
      position: absolute;
      color: #fff;
      font-size: 4.9rem;
      font-weight: bold;
      line-height: 1;
      -webkit-text-stroke: 2px v.$color1;
      @include m.sp {
        font-size: 2.6rem;
        left: 10px;
        top: 15px;
      }
    }
  }

  &__txt {
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 40px;
    @include m.sp {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    & + .c-table03 {
      margin-top: 2px;
    }
  }

  // .c-accordion__note .
  &__note {
    margin-top: 12px;
    font-size: 1.4rem;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: 0;
    width: 100%;
    display: flex;
    position: relative;
    padding-left: 15px;
    &::before {
      content: "※";
      position: absolute;
      top: 0;
      left: 0;
    }
    & + .c-accordion__note {
      margin-top: 6px;
    }
    &.u-mgl {
      &::before {
        content: "（注）";
        left: -22px;
      }
    }
  }

  &__img {
    display: block;
    text-align: center;
  }

  &__txt {
    & + .c-accordion__txt {
      margin-top: 37px;
      @include m.sp {
        margin-top: 16px;
      }
    }
  }

  .c-table03 {
    margin-top: 31px;
    margin-bottom: 6px;
    @include m.sp {
      margin-top: 16px;
    }

    & + .c-table03 {
      margin-top: 24px !important;
    }

    & + .c-accordion__txt {
      margin-top: 72px;
    }
  }

  .c-label02 {
    & + .c-label02 {
      margin-top: 50px;

      @include m.sp {
        margin-top: 25px;
      }
    }
  }
}

.c-modal01 {
  padding: 60px 0;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out;

  @include m.sp {
    padding-left: 15px;
    padding-right: 15px;
  }

  &.is-hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  &.item-start {
    align-items: flex-start;
  }

  // .c-modal01__box .
  &__box {
    padding: 26px 31px 53px;
    position: relative;
    z-index: 0;
    max-width: 634px;
    margin-left: auto;
    margin-right: auto;
    background: url(p.$path + "images/common/line1.png");
    background-repeat: repeat-y;
    background-size: 100% 100%;
    @include m.sp {
      padding: 30px 15px 15px;
    }

    &:before {
      left: 0;
      top: -11px;
      width: 100%;
      position: absolute;
      background: url(p.$path + "images/common/line-top.png") no-repeat center bottom / contain;
      content: "";
      height: 20px;
      z-index: 0;
      @include m.sp {
        top: -16px;
      }
    }

    // .c-modal01__box:after .
    &:after {
      left: 0;
      bottom: -70px;
      width: 100%;
      position: absolute;
      background: url(p.$path + "images/common/border1.png") no-repeat center bottom / contain;
      content: "";
      height: 200px;
      z-index: 0;
      @include m.sp {
        height: 20vw;
        bottom: -8.6vw;
      }
    }

    &.is-border2 {
      &:after {
        background-image: url(p.$path + "images/common/border2.png");
      }
    }

    &.is-border3 {
      &:after {
        background-image: url(p.$path + "images/common/border3.png");
      }
    }
  }

  // .c-modal01__img1 .
  &__img1 {
    margin-top: -11px;
    position: relative;
    z-index: 1;
    width: max-content;

    @include m.sp {
      margin-top: 30px;
      width: 70%;
    }

    // .c-modal01__img1.is-right .
    &.is-right {
      margin-left: auto;

      @include m.sp {
        text-align: right;
        margin-left: -50%;
        order: 2;
      }
    }

    &.is-style1 {
      margin-top: -23px;

      @include m.sp {
        margin-top: 30px;
      }
    }

    img {
      @include m.sp {
        width: 50%;
        display: inline-block;
      }
    }
  }

  // .c-modal01__texts .
  &__texts {
    left: 50%;
    bottom: 104px;
    transform: translateX(-50%);
    position: absolute;
    z-index: 2;
    color: #000;
    display: flex;
    justify-content: center;
    width: 100%;

    @include m.pc {
      padding-left: 31px;
    }

    @include m.sp {
      position: relative;
      left: auto;
      bottom: auto;
      transform: translateX(0);
      width: auto;
      flex: 1;
      margin-left: -60%;
    }

    &.is-style1 {
      @include m.pc {
        padding-left: 0;
      }
      @include m.sp {
        margin-left: 0;
        order: 1;
      }
    }

    &.is-style2 {
      @include m.pc {
        bottom: 90px;
        padding-left: 0;
      }

      @include m.sp {
        margin-left: 0;
        order: 1;
      }
    }

    // .c-modal01__texts.is-style3 .
    &.is-style3 {
      @include m.pc {
        bottom: 80px;
        padding-left: 0;
      }

      @include m.sp {
        margin-left: 0;
        order: 1;
      }
    }
  }

  // .c-modal01__close .
  &__close {
    padding-right: 30px;
    padding-top: 3px;
    top: 21px;
    // bottom: 33px;
    right: 24px;
    cursor: pointer;
    position: absolute;
    display: inline-block;
    z-index: 3;
    width: max-content;
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: 500;
    color: #000;
    background: url(p.$path + "images/common/icon-checked-close.png") no-repeat center right / 21px auto;
    white-space: nowrap;
    width: 76px;
  }

  // .c-modal01__text1 .
  &__text1 {
    display: flex;
    flex-direction: column;
    width: max-content;

    &__top {
      font-size: 2rem;
      line-height: 1;
      font-weight: 700;

      @include m.sp {
        line-height: 3rem;
      }
    }

    // .c-modal01__text1__bottom .
    &__bottom {
      font-size: 2.4rem;
      line-height: 1;
      font-weight: 500;
      letter-spacing: 2.3px;

      @include m.pc {
        margin-top: 8px;
      }

      @include m.sp {
        font-size: 2rem;
        line-height: 3rem;
      }
    }
  }

  // .c-modal01__img .
  &__img {
    // height: 282px;
    overflow: hidden;
    text-align: center;
    width: 100%;

    // @include m.sp {
    //   height: 200px;
    // }

    img {
      @include m.sp {
        width: 100%;
      }
    }
  }

  &__text {
    font-size: 1.7rem;
    line-height: 3.1rem;
    color: #000;
    text-indent: 16px;
    text-align: justify;
		font-weight: 500;

    @include m.sp {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  // .c-modal01__title .
  &__title {
    margin: 23px 0 18px;
    font-size: 3.1rem;
    line-height: 1.5;
    font-weight: 700;
    width: 100%;
    text-align: center;
    color: #000;

    @include m.sp {
      font-size: 2rem;
      line-height: 3rem;
    }
  }

  // .c-modal01__inner .
  &__inner {
    padding-top: 75px;
    width: 100%;
    max-width: 694px;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    height: max-content;

    @include m.sp {
      padding-left: 15px;
      padding-right: 15px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
    }
    &.style-right {
      justify-content: flex-end;
    }
  }
}

/*------------------------------------------------*/
.c-video01 {
  height: 235px;
  position: relative;
  display: block;
  transition: all 0.3s ease-in-out;
  @include m.hover {
    opacity: 0.8;
  }
  &__title {
    margin-bottom: 7px;
    font-size: 2.8rem;
    line-height: 35px;
    font-weight: 700;
    color: #fff;
    @include m.sp {
      font-size: 2rem;
      line-height: 1.5;
    }
  }

  &__text {
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 500;
    color: #fff;
    @include m.sp {
      font-size: 1.4rem;
      line-height: 1.5;
    }
  }

  // .c-video01__content .
  &__content {
    top: 50%;
    left: 61px;
    width: max-content;
    z-index: 1;
    position: absolute;
    transform: translateY(-50%);
    @include m.sp {
      right: auto;
      left: 10px;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
    border: none;
    position: relative;
    z-index: 0;
  }

  &__img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
/*------------------------------------------------
c-imgtxt01
------------------------------------------------*/
.c-imgtxt01 {
  &__content {
    margin-top: 26px;
    @include m.sp {
      margin-top: 20px;
    }
  }
  &__box {
    display: flex;
    column-gap: 37px;
    margin-top: 4px;
    @include m.sp {
      flex-direction: column;
    }
  }
  &__item {
    width: 100%;
    &:nth-of-type(n + 2) {
      margin-top: 20px;
    }
  }
  &__img {
    max-width: 312px;
    width: 100%;
    flex-shrink: 0;
    @include m.sp {
      margin: 0 auto 20px;
      max-width: 100%;
    }
    img {
      @include m.sp {
        width: 100%;
      }
    }
  }
  &__tb {
    width: 100%;
  }
  &__note {
    font-size: 1.6rem;
    font-weight: 500;
    margin-top: 8px;
    @include m.sp {
      font-size: 1.2rem;
    }
  }
  .c-table07 {
    &.style2 {
      .c-table07__ttl2 {
        padding: 1px 5px 0px;
        @include m.sp {
          padding: 3px 5px 0px;
        }
      }
    }
    &.style3 {
      .c-table07__ttl2 {
        padding: 1px 5px 0px;
      }
      td {
        line-height: 1.5;
      }
    }
  }
}

/*------------------------------------------------
c-boxtxt01
------------------------------------------------*/
.c-boxtxt01 {
  @include m.sp {
    margin-bottom: 25px;
  }
  &__inner {
    max-width: 938px;
    padding: 0 20px;
    margin: 0 auto;
    font-size: 1.6rem;
    line-height: calc(27 / 16);
    @include m.sp {
      font-size: 1.4rem;
    }
  }
  &__txt {
    font-weight: 500;
  }
  &__list1 {
    margin-top: 26px;
    counter-reset: boxtxtlist1;
    @include m.sp {
      margin-top: 20px;
    }

    &.u-disc {
      .c-boxtxt01__itemlist1 {
        padding-left: 15px;
        &:before {
          content: "・";
        }
      }
    }
  }
  &__imglist1 {
    display: flex;
    column-gap: 20px;
    margin-top: 10px;
    @include m.sp {
      column-gap: 10px;
      margin-top: 5px;
    }
    &.u-mgb50 {
      margin-bottom: 40px;
      @include m.sp {
        margin-bottom: 20px;
      }
    }
  }
  &__itemlist1 {
    position: relative;
    padding-left: 25px;
    font-weight: 500;
    @include m.sp {
      padding-left: 20px;
    }
    &:before {
      content: "";
      position: absolute;
      counter-increment: boxtxtlist1;
      content: counter(boxtxtlist1) ".";
      left: 0;
    }
  }
  &__list2 {
    margin-top: 27px;
    counter-reset: counterlist2;
  }
  &__itemlist2 {
    position: relative;
    padding-left: 41px;
    @include m.sp {
      padding-left: 30px;
    }

    &:before {
      counter-increment: counterlist2;
      content: "※" counter(counterlist2);
      position: absolute;
      left: 0;
    }
  }
  &__content {
    font-weight: 500;
    margin-top: 26px;
    @include m.sp {
      margin-top: 20px;
    }
  }
  &__box {
    font-weight: 500;
    &:not(:last-of-type) {
      margin-bottom: 35px;
      @include m.sp {
        margin-bottom: 20px;
      }
    }
  }
  &__img {
    margin-top: 15px;
  }
  &__tb1 {
    display: flex;
    @include m.sp {
      display: block;
    }
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
    &__head {
      margin-right: 10px;
      font-weight: 500;
    }
    &__body {
      font-weight: 500;
      //line-height: 1.6;
      padding: 10px 0;
      position: relative;
      padding-left: 27px;
      @include m.sp {
        padding-left: 20px;
      }
      span {
        position: absolute;
        left: 0;
      }
    }
  }
  &__tb2 {
    margin-top: 29px;
    @include m.sp {
      margin-top: 25px;
    }
    .c-table07 {
      margin-top: 26px;
      @include m.sp {
        margin-top: 20px;
      }
    }
  }
  &__tb3 {
    margin: 26px auto 29px;
    @include m.sp {
      margin: 20px auto 25px;
    }
  }
  &__note {
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: bold;
    margin-top: 7px;
    text-align: center;
    display: block;
    @include m.sp {
      font-size: 1.4rem;
      line-height: 1.5;
    }
  }
  &__note2 {
    font-size: 1.2rem;
    line-height: calc(20 / 12);
    margin-top: 10px;
    font-weight: 500;
    @include m.sp {
      margin-top: 5px;
    }

		&.dot{
			padding-left: 20px;
			position: relative;

			&::before{
				position: absolute;
				content: "注）";
				top: 0;
				left: 0;
			}
		}
  }
  &__txt1 {
    font-size: 1.6rem;
    line-height: 2.7rem;
    font-weight: 500;
    @include m.sp {
      font-size: 1.4rem;
    }
  }
  &__colimg1 {
    display: flex;
    gap: 91px;
    margin: 10px 0 29px;
    @include m.sp {
      gap: 30px;
      margin: 5px 0 25px;
    }
    &__item {
      max-width: 353px;
    }
  }
  &__colimg2 {
    display: flex;
    flex-wrap: wrap;
    gap: 34px 128px;
    margin: 42px 0 22px;
    @include m.sp {
      gap: 30px 0;
      flex-direction: column;
      margin: 20px 0 25px;
    }
    &__item {
      flex-shrink: 0;
      @include m.sp {
        flex-shrink: unset;
      }
    }
    &__txt1 {
      margin-bottom: 10px;
      font-weight: 500;
      @include m.sp {
        margin-bottom: 5px;
      }
    }
  }
  &__colimg3 {
    display: flex;
    flex-wrap: wrap;
    gap: 0 81px;
    margin: 26px 0 70px;
    @include m.sp {
      gap: 30px;
      flex-direction: column;
      margin: 20px 0 30px;
    }
    &__item {
      flex-shrink: 0;
      @include m.sp {
        flex-shrink: unset;
      }
    }
    &__txt1 {
      margin-bottom: 10px;
      font-weight: 500;
      @include m.sp {
        margin-bottom: 5px;
      }
    }
    &__txt2 {
      margin-top: 10px;
      @include m.sp {
        margin-top: 5px;
      }
    }
  }
  &__imgtxt1 {
    position: relative;
    margin: 49px 0 0;
    padding-bottom: 29px;
    @include m.sp {
      padding-left: 40px;
      padding-bottom: 0;
    }
    &__text {
      position: absolute;
      top: 0;
      left: 0;
      line-height: calc(27 / 16);
      font-weight: 500;
    }
    &__img {
      img {
        margin: 0 auto;
      }
    }
  }
  .c-title15 {
    margin-bottom: 9px;
  }
  &.is-style1 {
    background: #e2ebf2;
    padding: 30px 0 35px;
    @include m.sp {
      padding: 25px 0 25px;
    }
  }
  .u-mgt0 {
    margin-top: 0 !important;
  }
  .u-mgb0 {
    margin-bottom: 0 !important;
  }
  .u-mgb40 {
    margin-bottom: 40px;
    @include m.sp {
      margin-bottom: 25px;
    }
  }
}

.c-wrap{
	margin-left: 40px;
}
