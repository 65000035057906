@use "../settings/variable" as v;
@use "../settings/mixin" as m;
@use "../settings/path" as p;

.c-text01 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.6;
  @include m.sp {
    font-size: 1.3rem;
  }
}

.c-text02 {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 500;

  &.text-bold {
    font-weight: 700;
  }

  &.is-style1 {
    color: v.$color1;
    font-weight: 700;
  }
}

.c-text03 {
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 500;
}

.c-text04 {
  font-size: 1.6rem;
  line-height: 3.2rem;
  font-weight: bold;

  @include m.sp {
    line-height: 2.4rem;
  }
}

.c-text05 {
  font-size: 1.4rem;
  line-height: 3.2rem;

  @include m.sp {
    line-height: 1.5;
  }
}

.c-text06 {
  font-size: 2.4rem;
  line-height: 4rem;
  font-weight: 500;

  @include m.sp {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}

.c-text07 {
  font-size: 1.6rem;
  line-height: 2.6rem;
  font-weight: 500;
  @include m.sp {
    font-size: 1.2rem;
    line-height: 1.5;
  }
}

.c-imgtxt1 {
  $root: &;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  &__ttl {
    font-size: 2.6rem;
    line-height: 3.2rem;
    font-weight: bold;
    letter-spacing: -0.02em;
    @include m.sp {
      font-size: 2rem;
      line-height: 3rem;
    }
  }
  &__text {
    font-size: 2rem;
    line-height: 3.2rem;
    font-weight: 500;
    @include m.sp {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
  &__img {
    width: calc(50% - 13px);
    @include m.sp {
      width: 100%;
    }
    img {
      @include m.sp {
        width: 100%;
      }
    }
  }
  &__txt {
    width: calc(50% - 8px);
    @include m.sp {
      width: 100%;
      order: 2;
      margin-top: 15px;
    }
  }
  &__img {
    @include m.sp {
      order: 1;
    }
  }
  &.style1 {
    #{$root}__txt {
      padding-left: 5px;
      @include m.sp {
        padding-left: 0;
      }
    }
  }
}
