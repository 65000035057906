@use '../settings/variable' as v;
@use '../settings/mixin' as m;
@use '../settings/path' as p;

.c-footer {
  $r: &;
  background-color: v.$color3;
  padding-top: 24px;
  margin-top: 100px;
  @include m.sp {
    padding-top: 20px;
    margin-top: 45px;
  }

  &__cont {
    display: flex;
    justify-content: space-between;
    padding: 0 48px 0 40px;
    max-width: v.$base-width;
    margin: 0 auto;
    @include m.sp {
      display: block;
      padding: 0 20px;
    }
  }

  &__logo {
    text-align: center;
    @include m.sp {
      // width: 242px;
      max-width: 100%;
      margin: 0 auto 44px;
    }
    &1{
      width: 156px;
      @include m.sp {
        display: inline-block;
      }
    }
    &2{
      width: 250px;
      margin-left: 5px;
      margin-top: 11px;
      @include m.sp {
        display: inline-block;
      }
    }
  }

  &__img {
    display: flex;
    justify-content: flex-end;
    max-width: v.$base-width;
    margin: 0 auto;
    padding: 19px 48px 0 40px;
    @include m.sp {
      justify-content: center;
      padding: 10px 20px 0;
    }
  }

  &__copy {
    background-color: v.$color1;
    margin-top: 39px;
  }

  &__copyText {
    font-size: 1rem;
    line-height: 2;
    color: #ffffff;
    margin: 0 auto;
    max-width: v.$base-width;
    padding: 15px 40px;
    @include m.sp {
      font-size: v.vw(8);
      line-height: 1.25;
      text-align: center;
      padding: 16px 10px;
    }
  }

  &__nav {
    display: flex;
    justify-content: flex-end;
    @include m.sp {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 0 20px;
    }
  }

  &__navColumn {
    margin-left: 17px;
    flex-shrink: 0;
    @include m.sp {
      width: calc(50% - 10px);
      margin-left: 0;
      margin-bottom: 22px;

      &:nth-child(1) {
        order: 1;
      }

      &:nth-child(3) {
        order: 2;
      }

      &:nth-child(2) {
        order: 3;
      }

      &:nth-child(4) {
        order: 4;
      }

      &:nth-child(5) {
        order: 5;
        margin-left: auto;
        margin-top: -22px;
      }
    }
  }

  &__navMenu {
    position: relative;
  }

  &__navItem {
    font-weight: 500;
    font-size: 1rem;
    line-height: 2;
    @include m.sp {
      font-size: 1.3rem;
      line-height: 1.7142;
    }
  }

  &__navTitle {
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 1.666;
    letter-spacing: -0.1px;
    @include m.sp {
      font-size: 1.4rem;
      line-height: 1.7142;
    }

    &.is-info {
      margin-top: 47px;
      @include m.sp {
        margin-top: 0;
      }
    }
  }

  a {
    opacity: 1;
    transition: all 0.3s ease-in-out;
    @include m.hover {
      opacity: 0.8;
    }
  }
}
