// $filePath: '/dirname/assets/';

$base-width: 1080px;
$base-color: #000000;
$color1: #558bb2;
$color2: #4478a2;
$color3: #e2ebf2;
$color4: #9f5409;
$color5: #ffd905;
$color6: #696969;
$color7: #241a1a;
$color8: #707070;
$color9: #1f6fa8;
$color10: #103854;
$color11: #708fa5;
$color12: #ffe834;

@function vw($size) {
  // $size unit rem
  @return (($size * 100vw) / 375);
}
