@use '../settings/variable' as v;
@use '../settings/mixin' as m;
@use '../settings/path' as p;

/*------------------------------------------------------------
FORM
------------------------------------------------------------*/
.c-form {
  // .c-form__title .
  $r: &;
  max-width: 649px;
  margin: 63px auto 0;
  @include m.sp{
    margin-top: 30px;
  }
  &__checkbox {
    margin-top: 35px;
    font-size: 1.6rem;
    line-height: 2.6rem;
    font-weight: 700;
    color: #000;

    label {
      position: relative;
      cursor: pointer;
      display: inline-block;
    }

    input {
      position: absolute;
      width: 0;
      height: 0;
      opacity: 0;
    }

    // .c-form__checkbox__text .
    &__text {
      padding-left: 26px;

      &:before {
        top: 3px;
        left: 2px;
        width: 18px;
        height: 18px;
        border: 1px solid #000;
        background-color: #fff;
      }

      &:after {
        top: 6px;
        left: 5px;
        width: 12px;
        height: 12px;
        background-color: #000;
      }

      &:before,
      &:after {
        position: absolute;
        content: '';
      }
    }
  }

  // .c-form__title .
  &__title {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-weight: 700;

    @include m.sp {
      margin-bottom: 4px;
    }

    &__text {
      font-size: 1.6rem;
      line-height: 1.5;

      @include m.sp {
        line-height: 2.4rem;
      }
    }
  }

  &__row {
    // .c-form__row + .c-form__row .
    & + .c-form__row {
      margin-top: 27px;

      @include m.sp {
        margin-top: 21px;
      }
    }
  }

  // .c-form__text .
  &__text {
    margin-top: 12px;
    font-size: 1.6rem;
    line-height: 2;

    @include m.sp {
      margin-top: -3px;
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
  }

  // .c-form__label .
  &__label {
    margin-left: 9px;
    color: #ef7171;
    font-size: 1.6rem;
    line-height: 1;

    @include m.sp {
      margin-right: 8px;
      font-size: 1.2rem;
    }
  }

  &__input, textarea{
    border: none;
    box-sizing: border-box;
    &.error{
      border: 1px solid #EF7171;
    }
  }

  &__input {
    padding: 5px 20px;
    font-size: 1.6rem;
    line-height: 2.4rem;
    height: 46px;
    width: 100%;
    display: block;
    background-color: #fff;
    overflow: hidden;
    color: v.$base-color;
    font-weight: 500;
    @include m.sp {
      padding: 5px 12px;
      font-size: 1.4rem;
      line-height: 1.5;
      height: 40px;
    }
    &--small {
      max-width: 290px;
      @include m.sp {
        max-width: 100%;
      }
    }

  }

  // .c-form textarea .
  textarea {
    padding: 10px 20px;
    color: v.$base-color;
    background-color: #fff;
    width: 100%;
    height: 266px;
    resize: none;
    font-size: 1.6rem;
    line-height: 2.4rem;

    @include m.sp {
      height: 121px;
      font-size: 1.4rem;
      line-height: 1.5;
      padding: 10px 12px;
    }
  }

  // .c-form .c-btn02 .
  .c-btn02 {
    margin-top: 53px;
    @include m.sp {
      margin-top: 29px;
    }
  }

  // .c-form__list .
  &__list {
    display: flex;
    @include m.sp {
      flex-wrap: wrap;
      width: 100%;
      margin-top: 10px;
    }
  }
  &__form{
    margin-top: 39px;
    @include m.sp {
      margin-top: 30px;
    }
  }
  &__item {
    // .c-form__item:nth-child(n+2) .
    &:nth-child(n + 2) {
      margin-left: 45px;

      @include m.sp {
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
      }
    }

    label {
      display: flex;
      align-items: center;
      position: relative;
      font-size: 1.6rem;
      line-height: 21px;
      font-weight: bold;
      cursor: pointer;
      @include m.sp {
        font-size: 1.4rem;
        line-height: 2rem;
      }
      input{
        appearance: none;
        position: relative;
        right: 0;
        bottom: 0;
        left: 0;
        background: #fff;
        cursor: pointer;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px solid #558BB2;
        outline: none;
        margin-right: 6px;
        &:before{
          position: absolute;
          display: block;
          content: "";
          top: 50%;
          left: 50%;
          width: 12px;
          height: 12px;
          transform: translate(-50%, -50%);
          opacity: 0;
          background: #558BB2;
          border-radius: 50%;
        }
        &:checked:before {
          opacity: 1;
        }
      }
    }

    &__checkmark {
      position: absolute;
      left: 0;
      top: -2px;
      width: 24px;
      height: 24px;
      background: url(p.$path + 'images/common/icon-radio.svg') no-repeat
        center/cover;
      @include m.sp {
        width: 20px;
        height: 20px;
        top: 0;
      }
    }
  }

  &__confirm{
    font-size: 1.6rem;
    line-height: 2.6rem;
    font-weight: 500;
    @include m.sp{
      font-size: 1.4rem;
      line-height: 1.5;
    }
  }
  &__error{
    &__txt{
      color: #ef7171;
      font-weight: bold;
      margin-top: 5px;
      @include m.sp{
        font-size: 1.4rem;
      }
    }
  }
  ::placeholder{
    color: #9ca3af !important;
  }
}
