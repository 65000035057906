@use '../settings/variable' as v;
@use '../settings/mixin' as m;
@use '../settings/path' as p;

@use '../../../node_modules/sanitize.css/sanitize.css';
@use '../../../node_modules/sanitize.css/forms.css';

* {
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

li {
  list-style-type: none;
}

th {
  font-weight: inherit;
}

address {
  font-style: inherit;
}

fieldset {
  min-width: 0;
  padding: 0;
  border: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

button,
input,
select,
textarea {
  padding: 0;
  border: 0;
}

button:not(:disabled):not([aria-disabled='true']) {
  cursor: pointer;
}

html {
  font-size: 62.5%;
}

body {
  @include m.yugothic;
  font-weight: 400;
  font-size: 1.6rem;
  overflow: auto;
  height: 100vh;
  min-width: v.$base-width;
  color: #000000;
  opacity: 0;
  @include m.sp {
    overflow-x: hidden;
    overflow-y: auto;
    min-width: 320px;
  }

  &.loaded {
    opacity: 1;
    transition: opacity 0.5s cubic-bezier(0.26, 0.06, 0, 1);
  }

  &.is-fixed {
    @include m.pc {
      overflow: hidden;
    }

    @include m.sp {
      position: fixed;
      width: 100%;
    }
  }
}

// Safari用のハックは、Chromeに適用されないようにする
@supports (-webkit-touch-callout: none) {
  body {
    // Safari用のハック
    height: -webkit-fill-available;
  }
}

[v-cloak] {
  visibility: hidden;
}

.l-main {
  padding-top: 100px;
  @include m.sp {
    padding-top: 55px;
  }
}

.l-wrapper {
  position: relative;
}

.l-container {
  width: 100%;
  max-width: v.$base-width;
  margin: 0 auto;
  padding: 0 46px 0 40px;
  @include m.sp {
    padding: 0 20px;
  }
}

.l-cont1 {
  width: 100%;
  max-width: 797px;
  margin: 0 auto;

  @include m.sp {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.l-cont2 {
  width: 100%;
  max-width: 876px;
  margin: 0 auto;
  padding: 0 40px;
  @include m.sp {
    padding: 0 20px;
  }
}

.l-cont3 {
  width: 100%;
  max-width: 938px;
  margin: 0 auto;
  padding: 0 20px;
  @include m.sp {
    padding: 0 20px;
  }
}

.pc {
  @include m.mq(mdless) {
    display: none !important;
  }
}

.sp {
  @include m.mq(mdover) {
    display: none !important;
  }
}

select,
input,
textarea {
  box-sizing: border-box;
  border: none;
  background-color: #fff;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  box-shadow: none;
  outline: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  font-family: YuGothic, '游ゴシック', 'ヒラギノ角ゴ Pro',
    'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', sans-serif;
  &:focus,
  &:hover {
    outline: none;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    // box-shadow: none;
  }
}

button {
  cursor: pointer;
  background-color: inherit;
}

select {
  width: 100%;

  &::-ms-expand {
    display: none;
  }
}

input {
  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-input-placeholder {
    color: v.$base-color !important;
  }

  &:-ms-input-placeholder {
    color: v.$base-color !important;
  }

  &::placeholder {
    color: v.$base-color !important;
  }

  &::-ms-clear {
    display: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 100px white inset !important;
  }
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

textarea {
  resize: none;
  width: 100%;
  height: 100%;
  padding: 0 0;

  &::-webkit-input-placeholder {
    color: v.$base-color;
  }

  &:-ms-input-placeholder {
    color: v.$base-color;
  }

  &::placeholder {
    color: v.$base-color;
  }
}
.u-tel {
  @include m.pc {
    pointer-events: none;
  }
}
