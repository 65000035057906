@use "../settings/variable" as v;
@use "../settings/mixin" as m;
@use "../settings/path" as p;

.c-btn01 {
  $r: &;
  position: relative;
  display: block;
  width: 392px;
  max-width: 100%;

  @include m.sp {
    width: 260px;
  }

  &__link {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.25;
    background-color: v.$color1;
    color: #ffffff;
    border-radius: 5px;
    display: block;
    text-align: center;
    padding: 22px 20px;
    width: 100%;
    transition: all 0.3s ease-in-out;
    white-space: normal;

    @include m.sp {
      font-size: 1.3rem;
      padding: 10px 10px;
    }

    @include m.hover {
      background-color: rgba(v.$color1, 0.9);
    }
  }

  &__text {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;

    &:after {
      margin-left: 10px;
      content: "";
      width: 13px;
      height: 13px;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: rotate(45deg);
      transition: 0.3s ease-in-out;
      margin-bottom: 1px;
      @include m.sp {
        width: 8px;
        height: 8px;
        margin-left: 7px;
        margin-bottom: 2px;
      }
    }
  }

  &__arrow {
    font-size: 1.6rem;
    display: inline-block;
    margin-left: 8px;
    margin-top: 6px;

    @include m.sp {
      font-size: 1.1rem;
      margin-top: 1px;
    }
  }

  &--style1 {
    #{$r}__link {
      background-color: #ffffff;
      color: v.$color1;
      border: 2px solid v.$color1;
      padding: 20px 20px;

      @include m.sp {
        padding: 15px 10px;
        font-size: 1.4rem;
      }

      @include m.hover {
        background-color: v.$color1;
        color: #ffffff;
      }
    }
  }

  &.is-style2 {
    width: 100%;
    text-align: center;

    .c-btn01 {
      &__link {
        display: inline-block;
        max-width: 100%;
        width: max-content;
        padding: 14px 31px 13px;

        @include m.sp {
          padding: 10px 10px;
        }
      }

      &__text {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;

        &:after {
          margin-left: 7px;
          content: "";
          width: 13px;
          height: 13px;
          border-top: 2px solid #fff;
          border-right: 2px solid #fff;
          transform: rotate(45deg);
          transition: 0.3s ease-in-out;

          @include m.sp {
            width: 8px;
            height: 8px;
            margin-left: 5px;
          }
        }
      }
    }
  }

  &.is-style3 {
    width: 100%;
    text-align: center;

    .c-btn01 {
      &__link {
        display: inline-block;
        max-width: 100%;
        width: max-content;
        padding: 10px 31px;
        font-size: 2.4rem;

        @include m.sp {
          font-size: 1.3rem;
          padding: 12px 15px !important;
        }
      }

      &__text {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;

        &:after {
          margin-left: 10px;
          content: "";
          width: 15px;
          height: 15px;
          border-top: 1px solid #fff;
          border-right: 1px solid #fff;
          transform: rotate(45deg);
          transition: 0.3s ease-in-out;

          @include m.sp {
            width: 8px;
            height: 8px;
          }
        }
      }
    }
  }

  &.is-style4 {
    width: 100%;
    text-align: center;

    .c-btn01 {
      &__link {
        display: inline-block;
        max-width: 100%;
        width: max-content;
        padding: 11px 25px 13px 20px;
        font-size: 2rem;

        @include m.sp {
          font-size: 1.1rem;
          padding: 10px 10px;
        }
      }

      &__text {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
      }
    }
  }

  &.is-style5 {
    width: 100%;
    text-align: center;

    .c-btn01 {
      &__link {
        display: inline-block;
        max-width: 100%;
        width: max-content;
        padding: 9px 20px 8px 22px;
        font-size: 1.6rem;
        line-height: 2rem;
        border-radius: 20px;

        @include m.sp {
          font-size: 1.2rem;
          padding: 12px 15px;
        }
      }

      &__text {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;

        &:after {
          margin-left: 0px;
          content: "";
          width: 12px;
          height: 12px;
          border-top: 1px solid #fff;
          border-right: 1px solid #fff;
          transform: rotate(45deg);
          transition: 0.3s ease-in-out;

          @include m.sp {
            width: 8px;
            height: 8px;
            margin-left: 6px;
          }
        }
      }

      &__text1 {
        font-size: 1.2rem;
        line-height: 2rem;

        @include m.sp {
          font-size: 1rem;
        }
      }
    }
  }

  &.is-style6 {
    width: 100%;
    text-align: center;

    .c-btn01 {
      &__link {
        display: inline-block;
        max-width: 100%;
        width: max-content;
        padding: 17px 35px 14px 46px;
        font-size: 1.6rem;
        line-height: 2rem;
        border-radius: 5px;

        @include m.sp {
          font-size: 1.1rem;
          padding: 6px 15px;
        }
      }

      &__text {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;

        &:after {
          margin-left: 15px;
          content: "";
          width: 12px;
          height: 12px;
          border-top: 2px solid #fff;
          border-right: 2px solid #fff;
          transform: rotate(45deg);
          transition: 0.3s ease-in-out;

          @include m.sp {
            width: 8px;
            height: 8px;
            margin-left: 6px;
            border-top-width: 1px;
            border-right-width: 1px;
          }
        }
      }

      &__text1 {
        font-size: 1.2rem;
        line-height: 2rem;

        @include m.sp {
          font-size: 1rem;
        }
      }
    }
  }
}

.c-btnList01 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__item {
    width: 195px;
    margin: 0 23px;
  }

  &__note {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 500;
    padding-left: 13px;
    position: relative;
    margin-top: 4px;

    @include m.sp {
      line-height: 1.4;
      margin: 5px 0 10px;
    }

    &::before {
      content: "※";
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .c-btn01 {
    &__link {
      padding: 20px 5px;
      line-height: 1;

      @include m.sp {
        padding: 23px 5px;
        line-height: 1.1;
      }
    }
  }
}

.c-btn02 {
  width: 186px;
  position: relative;
  z-index: 2;
  transition: 0.3s ease-in-out;

  @include m.hover {
    opacity: 0.7;
  }

  &__main {
    font-size: 2.4rem;
    font-weight: bold;
    background-color: v.$color1 !important;
    color: #ffffff;
    padding: 9px 30px;
    border-radius: 27px;
    overflow: hidden;
    cursor: pointer;
    display: block;
    width: max-content;
    max-width: 100%;
    text-align: center;
    transition: 0.3s ease-in-out;
    border: none;
    position: relative;
    width: 100%;

    @include m.sp {
      font-size: 1.6rem;
    }

    @include m.hover {
      opacity: 1;
    }
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background: #637e91;
    position: absolute;
    bottom: -6px;
    left: 0;
    border-radius: 27px;
    z-index: -1;
  }

  &.is-center {
    margin-left: auto;
    margin-right: auto;
  }

  &.style1 {
    .c-btn02__main {
      background-color: #637e91 !important;
    }

    &::after {
      background: #558bb2;
    }
  }
}

.c-btn03 {
  display: block;
  width: 100%;
  max-width: 195px;
  border: 1px solid #00aaef;
  padding: 17px 0;
  text-align: center;

  img {
    display: inline-block;
  }
}

.c-btn04 {
  color: #000;
  font-weight: 500;
  font-size: 2rem;
  line-height: calc(24 / 20);
  position: relative;
  background: #ffe7ba;
  max-width: 270px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 27px;

  @include m.sp {
    font-size: 1.6rem;
    height: 40px;
    border-radius: 20px;
  }

  &:before {
    content: "";
    width: 30px;
    height: 27px;
    background: url(p.$path + "images/common/icon_cart.svg") center/cover no-repeat;
    margin-right: 7px;

    @include m.sp {
      width: 25px;
      height: 22px;
    }
  }
}
