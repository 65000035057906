@use "../settings/variable" as v;
@use "../settings/mixin" as m;
@use "../settings/path" as p;

.c-title01 {
  $r: &;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.is-center {
    justify-content: center;
  }

  &.profile {
  }

  &--white {
    #{$r}__inner {
      color: v.$color2;
    }

    #{$r}__jap {
      color: #ffffff;
    }
  }

  &__inner {
    position: relative;
    @include m.impact;
    font-size: 12rem;
    line-height: 1;
    color: rgba(v.$color1, 0.3);
    text-align: center;
    margin: -12px 0;
    pointer-events: none;
    white-space: nowrap;
    @include m.sp {
      margin: -(v.vw(4)) 0;
      font-size: v.vw(41);
    }
  }

  &__jap {
    @include m.yugothic;
    font-size: 3.2rem;
    line-height: 1.2;
    font-weight: bold;
    color: #000000;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    white-space: normal;
    @include m.sp {
      font-size: v.vw(10);
    }

    i.ic-arrow01 {
      margin-right: 18px;
      @include m.sp {
        position: relative;
        margin-right: 8px;
        width: v.vw(8);
        height: v.vw(7);
        top: -1px;
      }
    }
  }
}

/*------------------------------------------------*/
.c-title02 {
  font-size: 3.2rem;
  line-height: 1;
  font-weight: bold;
  color: #000;
  text-align: center;
  @include m.sp {
    font-size: 2rem;
    line-height: 3rem;
  }
}

/*------------------------------------------------*/
.c-title03 {
  font-size: 2.4rem;
  line-height: 1;
  font-weight: bold;
  color: #000;
  text-align: center;
  border-top: 4px solid #708fa5;
  border-bottom: 4px solid #708fa5;
  padding: 14px 0;
  @include m.sp {
    font-size: 2rem;
    border-top: 2px solid #708fa5;
    border-bottom: 2px solid #708fa5;
    padding: 10px 0;
  }
}

/*------------------------------------------------*/
.c-title04 {
  font-size: 2.4rem;
  line-height: 1;
  font-weight: bold;
  color: #fff;
  background: #558bb2;
  padding: 10px 20px 6px;
  @include m.sp {
    font-size: 2rem;
    padding: 6px 20px;
  }
}

/*------------------------------------------------*/
.c-title05 {
  font-size: 2.4rem;
  line-height: 3.9rem;
  font-weight: 700;
  color: v.$color1;

  @include m.sp {
    font-size: 2rem;
    line-height: 3rem;
  }
}

/*------------------------------------------------*/
.c-title06 {
  font-size: 1.6rem;
  line-height: 3.2rem;
  font-weight: 700;
  color: #000;
  @include m.sp {
    line-height: 2.4rem;
  }

  &.is-color1 {
    color: v.$color1;
  }
}

/*------------------------------------------------*/
.c-title07 {
  font-weight: 700;
  position: relative;
  text-align: center;

  &__en {
    opacity: 0.3;
    position: relative;
    z-index: 0;
    line-height: 1;
    font-size: 14rem;
    @include m.impact;
    color: v.$color1;
    text-transform: uppercase;
    @include m.sp {
      font-size: 8rem;
    }
  }

  // .c-title07__jp .
  &__jp {
    top: 54%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 1;
    color: v.$color1;
    font-size: 3.2rem;
    line-height: 1;
    white-space: nowrap;
    @include m.sp {
      font-size: 1.7rem;
    }
  }
}

/*------------------------------------------------*/
.c-title08 {
  padding: 34px 20px;
  font-size: 2.8rem;
  line-height: 1.5;
  width: 100%;
  text-align: center;
  font-weight: 700;
  color: #fff;
  display: block;
  transition: all 0.3s ease-in-out;
  @include m.bg-linear;
  @include m.sp {
    font-size: 2rem;
    padding: 30px 10px;
  }
  @include m.hover {
    opacity: 0.8;
  }

  &.is-style1 {
    padding: 31px 20px;
    border: 4px solid v.$color1;
    background: #fff;
    color: v.$color1;
    @include m.sp {
      padding: 30px 10px;
      border: 2px solid v.$color1;
    }
  }
}

/*------------------------------------------------*/
.c-title09 {
  line-height: 1.3;
  border-bottom: 2px solid v.$color11;
  width: max-content;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 3.2rem;
  font-weight: 700;
  color: #000;
  @include m.sp {
    font-size: 2.4rem;
  }
}

/*------------------------------------------------*/
.c-title10 {
  padding-bottom: 7px;
  color: v.$color1;
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 1.5;
  width: 100%;
  border-bottom: 3px solid v.$color1;

  @include m.sp {
    font-size: 2rem;
    padding-bottom: 4px;
    border-bottom-width: 1px;
  }

  &--style01 {
    display: inline-block;
    width: auto;
  }
}

/*------------------------------------------------*/
.c-title11 {
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-weight: bold;
  @include m.sp {
    font-size: 1.8rem;
  }
  &::before {
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #558bb2;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: 1px;
    @include m.sp {
      width: 13px;
      height: 13px;
      margin-right: 5px;
      top: 0px;
    }
  }
}

.c-title14 {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 2.4rem;
  position: relative;
  color: #558bb2;
  padding-left: 8px;
  @include m.sp {
    font-size: 1.8rem;
    line-height: 2.1rem;
  }
  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 3px;
    left: 0;
    top: 0;
    background: #558bb2;
  }
}

.c-title15 {
  font-size: 1.6rem;
  font-weight: bold;
  line-height: calc(24 / 16);
  position: relative;
  color: #000;
  padding-left: 18px;
  &:before {
    content: "";
    position: absolute;
    height: 12px;
    width: 12px;
    left: 0;
    top: 5px;
    background: #558bb2;
  }
}

/*------------------------------------------------*/
.c-title12 {
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-weight: bold;
  color: #558bb2;
  text-align: center;
  @include m.sp {
    font-size: 1.8rem;
  }
}

/*------------------------------------------------*/
.c-title13 {
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: bold;
  text-align: left;
}
