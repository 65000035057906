@use "../settings/variable" as v;
@use "../settings/mixin" as m;
@use "../settings/path" as p;

.c-table01 {
  width: 100%;
  max-width: 547px;
  margin: 0 auto;

  th {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: bold;
    text-align: left;
    width: 115px;
    padding-bottom: 24px;
    vertical-align: top;

    @include m.sp {
      font-size: 1.4rem;
      line-height: 1.4;
      padding-bottom: 15px;
    }
  }

  td {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 500;
    padding-bottom: 24px;

    @include m.sp {
      font-size: 1.4rem;
      line-height: 1.4;
      padding-bottom: 15px;
    }

    .c-list03 {
      margin-left: -24px;
    }
  }

	&__txt{
		width: 90px;
		display: inline-block;

		@include m.sp{
			display: block;
		}
	}
}

.c-table02 {
  width: 100%;
  font-size: 1.6rem;
  line-height: 2.4rem;

  @include m.sp {
    font-size: 1.4rem;
    line-height: 1.4;
  }

  th {
    font-weight: bold;
    width: 56px;
    vertical-align: top;
  }

  td {
    font-weight: 500;
  }

  &__month {
    width: 47px;
    text-align: right;
    vertical-align: top;
    font-weight: bold !important;
  }

  &__text {
    padding-left: 25px;

    &.u-edit {
      font-feature-settings: "palt";
      letter-spacing: 0.4px;
    }

    span {
      font-size: 1.4rem;
    }
  }
}

.c-table03 {
  width: 100%;
  font-size: 1.6rem;
  line-height: 2.4rem;

  // table-layout: fixed;
  @include m.sp {
    font-size: 1.4rem;
    line-height: 1.4;
    width: 666px;
  }

  // .c-table03 th, .c-table03 td .
  th,
  td {
    font-weight: bold;
    font-size: 1.6rem;
    vertical-align: middle;
    border: 2px solid v.$color1;

    @include m.sp {
      line-height: 2.4rem;
    }
  }

  th {
    padding: 7px 5px 6px;
    background: v.$color1;
    color: #fff;
    border-right: 2px solid #fff;
    text-align: center;

    &:last-child {
      border: 2px solid v.$color1;
    }
  }

  td {
    padding: 14px 5px;
    background: #fff;
    text-align: left;

    &.is-bg1 {
      background-color: v.$color3;
    }

    &.center {
      text-align: center;
    }

    &.u-pd1 {
      padding-left: 20px;
      padding-right: 10px;
    }

    &.u-pd2 {
      padding-left: 15px;
      padding-right: 10px;
    }

    &.u-pd3 {
      padding-left: 44px;
      padding-right: 10px;
    }

    img {
      display: inline-block;
    }
  }

  &--style1 {
    margin-left: -65px;

    @include m.sp {
      margin-left: 0;
    }

    td {
      font-size: 1.4rem;
      padding: 26px 5px;

      @include m.sp {
        font-size: 1.4rem;
        padding: 26px 4px;
      }

      &.u-pd4 {
        padding-left: 11px;
        padding-right: 11px;

        @include m.sp {
          padding-left: 4px;
          padding-right: 4px;
        }
      }
    }

    &.u-edit {
      width: 110%;

      @include m.sp {
        width: 666px;
      }
    }
  }

  &--style2 {
    margin-top: 0 !important;

    th,
    td {
      font-size: 1.6rem;
      line-height: 1.5;
      text-align: center;
    }

    tbody {
      th,
      td {
        padding: 1px 0;
      }

      tr {
        &:first-child {
          th,
          td {
            padding: 20px 0;
          }
        }
      }

      tr {
        &:nth-child(2) {
          th,
          td {
            padding: 8px 0;
          }
        }
      }
    }

    th {
      border-right: none;
      border-bottom: 2px solid #fff;

      &.border-bottom-blue {
        border-bottom-color: v.$color1;
      }
    }

    tr {
      &:last-child {
        th {
          border-bottom-color: v.$color1;
        }
      }
    }
  }

  .w-17 {
    width: 17%;
  }

  .w-18 {
    width: 18.2%;
  }

  .w-21 {
    width: 21%;
  }

  .w-22 {
    width: 22%;
  }

  .w-24 {
    width: 24%;
  }

  .w-15 {
    width: 15.2%;
  }

  .w-16 {
    width: 16%;
  }

  .w-14 {
    width: 14%;
  }

  .w-12 {
    width: 12%;
  }
}

.c-table4 {
  width: 100%;
  font-size: 2rem;
  line-height: 3.2rem;
  font-weight: 500;

  @include m.sp {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  th {
    width: 175px;
    vertical-align: top;
    text-align: left;

    @include m.sp {
      width: 93px;
    }
  }

  &__small {
    font-size: 1.6rem;
    line-height: 3.2rem;

    @include m.sp {
      font-size: 1.4rem;
      line-height: 2.4rem;
    }
  }

  &__list {
    li {
      font-size: 1.6rem;
      line-height: 3.2rem;
      position: relative;
      padding-left: 15px;
      display: flex;

      @include m.sp {
        font-size: 1.4rem;
        line-height: 2.4rem;
      }

      &::before {
        content: "※";
        position: absolute;
        top: 2px;
        left: 0;
      }

      span {
        flex: 1;
        margin-left: 2px;
      }
    }
  }

  &__img {
    text-align: right;
    display: block;
    margin-top: 11px;

    img {
      display: inline-block;
    }
  }

  .u-balance {
    width: 84px;
    height: 28px;
    display: block;
    text-align: justify;

    @include m.sp {
      width: 64px;
    }

    &::after {
      content: "";
      display: inline-block;
      width: 100%;
    }
  }
}

.c-table05 {
  width: 100%;
  $root: &;

  &__wrap {
    width: 100%;
    overflow-x: auto;
  }

  &__tb {
    width: 898px;
    border-collapse: collapse;
    border: 1px solid #558bb2;

    th {
      font-size: 1.6rem;
      line-height: 1.9rem;
      font-weight: bold;
      background: #558bb2;
      color: #fff;
      padding: 14px 0;
      text-align: center;

      &:not(:last-child) {
        border-right: 1px solid #fff;
      }

      &.w76 {
        width: 76px;
      }

      &.w90 {
        width: 90px;
      }

      &.w80 {
        width: 80px;
      }

      &.w87 {
        width: 87px;
      }

      &.w95 {
        width: 95px;
      }

      &.w65 {
        width: 65px;
      }

      &.w102 {
        width: 102px;
      }

      &.w106 {
        width: 106px;
      }

      &.w108 {
        width: 108px;
      }

      &.w112 {
        width: 112px;
      }

      &.w116 {
        width: 116px;
      }

      &.w118 {
        width: 118px;
      }

      &.w122 {
        width: 122px;
      }

      &.w126 {
        width: 126px;
      }

      &.w133 {
        width: 133px;
      }

      &.w130 {
        width: 130px;
      }

      &.w140 {
        width: 140px;
      }

      &.w143 {
        width: 143px;
      }

      &.w153 {
        width: 153px;
      }
    }

    td {
      font-size: 1.2rem;
      line-height: 1.44rem;
      color: #000;
      font-weight: 500;
      text-align: center;
      border: 1px solid #558bb2;
      padding: 7px 0 6px;

      &.w48 {
        width: 48px;
      }
    }
  }

  &__sub {
    font-size: 1.2rem;
    line-height: 1.44rem;
    text-align: right;
    color: #000;
    text-align: right;
  }

  &.style1 {
    #{$root}__tb {
      th {
        padding: 9px 0;
      }
    }
  }

  &.style2 {
    #{$root}__tb {
      th {
        padding: 9px 0;
      }

      td {
        padding: 13px 0;
      }
    }
  }

  &.style3 {
    #{$root}__tb {
      th {
        padding: 4px 0;
      }

      td {
        padding: 8px 0 7px;
      }
    }
  }

  &.style4 {
    #{$root}__tb {
      th {
        padding: 4px 0;
      }

      td {
        padding: 15px 0 14px;
      }
    }
  }

  &.style5 {
    #{$root}__tb {
      th {
        padding: 6px 0;
      }

      td {
        padding: 8px 0 7px;
        font-feature-settings: "palt";
      }
    }
  }
}

.c-table06 {
  width: 100%;
  max-width: 406px;
  border-collapse: collapse;
  border: 1px solid #558bb2;

  @include m.sp {
    max-width: 100%;
  }

  &__ttl1 {
    font-size: 1.6rem;
    line-height: 1.92rem;
    font-weight: bold;
    background: #558bb2;
    color: #fff;
    padding: 8px 0;
    text-align: center;
    @include m.sp {
      font-size: 1.4rem;
      padding: 8px 5px;
    }
  }

  &__ttl2 {
    font-size: 1.6rem;
    line-height: 2;
    font-weight: bold;
    color: #558bb2;
    border: 1px solid #558bb2;
    text-align: left;
    padding: 1px 16px 0;
    width: 123px;
    background: white;

    @include m.sp {
      line-height: 1.6;
      font-size: 1.2rem;
      padding: 3px 10px;
    }
  }

  td {
    font-size: 1.6rem;
    line-height: 2;
    font-weight: bold;
    color: #000;
    border: 1px solid #558bb2;
    padding: 1px 20px 0;
    background: white;

    @include m.sp {
      line-height: 1.6;
      font-size: 1.2rem;
      padding: 3px 10px;
    }
  }
}

.c-grouptable1 {
  display: flex;
  align-items: flex-start;

  @include m.sp {
    display: block;
  }

  .c-table06 {
    &:first-child {
      margin-right: 20px;

      @include m.sp {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}

.c-table07 {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #558bb2;
  $root: &;

  @include m.sp {
    overflow-x: auto;
  }

  tr {
    &:last-child {
      #{$root}__ttl2 {
        border-bottom: 0;
      }
    }
  }

  &__ttl1 {
    font-size: 1.6rem;
    line-height: 1.92rem;
    font-weight: bold;
    color: #558bb2;
    padding: 8px 0;
    border: 1px solid #558bb2;
    text-align: center;

    @include m.sp {
      font-size: 1.3rem;
      padding: 8px 5px;
    }
  }

  &__ttl2 {
    font-size: 1.6rem;
    line-height: 2;
    font-weight: bold;
    color: #fff;
    text-align: left;
    padding: 1px 16px 0;
    width: 273px;
    background: #558bb2;
    border-bottom: 1px solid #fff;

    @include m.sp {
      line-height: 1.6;
      font-size: 1.2rem;
      width: 140px;
      padding: 3px 4px;
    }
  }

  &__list1 {
    list-style: none;
    counter-reset: my-counter;

    li {
      counter-increment: my-counter;
      position: relative;
      display: flex;

      &::before {
        content: counter(my-counter) ". ";
        margin-right: 10px;
      }

      > span {
        flex: 1;
      }
    }
  }
  &__notetd {
    font-size: 1.4rem;
    line-height: calc(32 / 14);
    display: block;
  }
  td {
    font-size: 1.6rem;
    line-height: 2;
    font-weight: bold;
    color: #000;
    border: 1px solid #558bb2;
    padding: 1px 15px 0;

    @include m.sp {
      line-height: 1.6;
      font-size: 1.2rem;
      padding: 3px 7px;
    }
  }

  &.style1 {
    #{$root}__ttl2 {
      width: 170px;
      text-align: center;
      padding: 0 16px;

      @include m.sp {
        width: 120px;
        padding: 3px 5px;
      }
    }

    td {
      padding: 0 28px;

      @include m.sp {
        padding: 3px 10px;
      }
    }
  }

  &.style2 {
    max-width: 550px;

    @include m.sp {
      max-width: 100%;
    }

    #{$root}__ttl2 {
      width: 31%;
      text-align: center;
      padding: 5px 5px 4px;

      @include m.sp {
        width: 120px;
        padding: 3px 5px;
      }
    }

    td {
      text-align: center;
      line-height: 2.4rem;
      padding: 5px 5px 4px;

      @include m.sp {
        line-height: 1.6;
      }

      &.w33 {
        width: 35%;
      }
    }
  }

  &.style3 {
    max-width: 550px;

    @include m.sp {
      max-width: 100%;
    }

    #{$root}__ttl2 {
      width: 31%;
      text-align: center;
      padding: 5px 5px 4px;

      @include m.sp {
        width: 120px;
        padding: 3px 5px;
      }
    }
  }

  &.style4 {
    #{$root}__ttl2 {
      width: 170px;
      text-align: center;
      padding: 0 16px;

      @include m.sp {
        width: 120px;
        padding: 3px 5px;
      }
    }

    td {
      padding: 5px 42px 4px;
      line-height: 2.4rem;

      @include m.sp {
        padding: 3px 10px;
        line-height: 1.6;
      }
    }
  }

  &.style5 {
    #{$root}__ttl2 {
      width: 170px;
      padding: 0 16px;

      @include m.sp {
        width: 120px;
        padding: 3px 5px;
      }
    }

    td {
      padding: 1px 23px 0;

      @include m.sp {
        padding: 3px 10px;
      }
    }
  }

  &.style6 {
    #{$root}__ttl2 {
      width: 170px;
      padding: 0 16px;

      @include m.sp {
        width: 100px;
        padding: 3px 5px;
      }
    }

    td {
      padding: 0 23px;

      @include m.sp {
        padding: 3px 10px;
      }
    }
  }
  &.style7 {
    #{$root}__ttl2 {
      width: 187px;
      padding: 0 0 0 15px;

      @include m.sp {
        width: 100px;
        padding: 3px 5px;
      }
    }

    td {
      padding: 0 10px;

      @include m.sp {
        padding: 3px 5px;
      }
    }
  }
}

.c-table08 {
  border-collapse: collapse;
  width: 100%;

  @include m.sp {
    border: 1px solid #558bb2;
  }

  tr {
    @include m.sp {
      display: flex;
      flex-wrap: wrap;
    }

    &:first-child {
      th {
        border-bottom: 1px solid #fff;
      }
    }

    &:last-child {
      th,
      td {
        @include m.sp {
          border-bottom: 0 !important;
        }
      }
    }
  }

  th {
    width: 170px;
    background: #558bb2;
    font-size: 1.6rem;
    line-height: 2;
    font-weight: bold;
    color: #fff;
    text-align: center;
    border-bottom: 1px solid #558bb2;
    padding: 12px 14px;

    @include m.sp {
      border-bottom: 1px solid #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1.6;
      font-size: 1.2rem;
      width: 100px;
    }
  }

  td {
    border: 1px solid #558bb2;
    font-size: 1.6rem;
    line-height: 2.6rem;
    font-weight: 500;
    color: #000;
    padding: 12px 14px;

    @include m.sp {
      width: calc(100% - 100px);
      border: 0;
      border-bottom: 1px solid #558bb2;
      line-height: 1.6;
      font-size: 1.2rem;
      padding: 10px;
    }

    &.w31 {
      width: 31.1%;

      @include m.sp {
        width: calc(100% - 100px);
      }
    }

    &.u-left {
      padding: 5px 6px;

      @include m.sp {
        padding: 10px;
      }
    }
  }
}
