@use '../settings/variable' as v;
@use '../settings/mixin' as m;
@use '../settings/path' as p;

@font-face {
  font-family: 'Impact';
  src: url(p.$path + 'fonts/Impact.eot');
  src: url(p.$path + 'fonts/Impact.eot?#iefix') format('embedded-opentype'),
  url(p.$path + 'fonts/Impact.woff2') format('woff2'),
  url(p.$path + 'fonts/Impact.woff') format('woff'),
  url(p.$path + 'fonts/Impact.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
