@use "../settings/variable" as v;
@use "../settings/mixin" as m;
@use "../settings/path" as p;

.c-block01 {
  $r: &;
  display: flex;
  padding: 0 40px;

  @include m.sp {
    padding: 0 20px;
  }

  &--size1 {
    #{$r}__img {
      width: 57.2%;
    }

    #{$r}__body {
      width: 42.8%;
    }

    .c-list01__text {
      color: #fff;

      .ic-arrow02 {
        background: url(p.$path + "images/common/icon-arrow02-w.svg") no-repeat center/100%;
      }
    }
  }

  &__img {
    width: 50.6%;

    @include m.sp {
      width: 50% !important;
    }
  }

  &__body {
    width: 49.4%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @include m.sp {
      width: 50% !important;
    }

    &:last-child {
      padding-left: 40px;

      @include m.sp {
        padding-left: 18px;
      }
    }

    &:first-child {
      padding-right: 40px;

      @include m.sp {
        padding-right: 18px;
      }
    }

    &.is-center {
      justify-content: center;

      #{$r}__title1 {
        margin-top: 0;
      }
    }
  }

  &__list1 {
    margin-top: 10px;
    padding-left: 10px;

    @include m.sp {
      padding-left: 0;
      margin-top: v.vw(1);
    }

    &.style1 {
      padding-left: 70px;

      @include m.sp {
        padding-left: 0;
      }
    }
  }

  &__title1 {
    cursor: pointer;
  }

  .c-title01__inner {
    &.is-active {
      .ic-arrow01 {
        transform: rotate(0) !important;
      }
    }
  }

  .js-accon-cont {
    display: none;
  }

  .c-list01__text {
    @include m.sp {
      display: block;
    }
  }
}

.c-block02 {
  display: flex;
  flex-wrap: wrap;

  &__txt {
    flex: 1;
    margin-right: 20px;

    @include m.sp {
      width: 100%;
      order: 2;
      margin-right: 0;
    }
  }

  &__table {

    th,
    td {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    th {
      font-weight: bold;
      text-align: left;
      vertical-align: top;
      min-width: 48px;
    }

    td {
      font-weight: 500;
      padding-left: 9px;
    }
  }

  &__link {
    color: #256695;

    @include m.hover {
      text-decoration: underline;
    }
  }

  &__map {
    width: 47%;

    @include m.sp {
      width: 100%;
      order: 1;
      order: 1;
    }

    iframe {
      width: 100%;
      height: 295px;
    }
  }

  .tel {
    pointer-events: none;

    @include m.sp {
      pointer-events: all;
    }
  }
}

.c-block03 {
  &__img {
    width: 48.4%;

    @include m.pc {
      padding-top: 7px;
    }

    @include m.sp {
      width: 100%;
      margin-top: 20px;
      text-align: center;
    }

    img {
      box-shadow: 0 3px 6px rgba($color: #000, $alpha: 0.16);

      @include m.sp {
        display: inline-block;
      }
    }
  }

  &__labeltext {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    @include m.sp {
      display: block;
    }

    &+.c-block03__labeltext {
      margin-top: 31px;

      @include m.sp {
        margin-top: 15px;
      }
    }
  }

  &__content {
    @include m.pc {
      flex: 1;
      padding-right: 20px;
    }
  }

  // .c-block03__imgtext .
  &__imgtext {
    margin-top: 27px;

    @include m.pc {
      display: flex;
    }

    @include m.sp {
      margin-top: 20px;
    }
  }

  // .c-block03 .c-title05 .
  .c-title05 {
    margin-bottom: 14px;

    @include m.sp {
      margin-bottom: 10px;
    }
  }

  // .c-block03 .c-title06 .
  .c-title06 {
    margin-bottom: 11px;

    @include m.sp {
      margin-bottom: 10px;
    }
  }

  // .c-block03 .c-label01 .
  .c-label01 {
    margin-right: 24px;
    padding: 4px 14px 2px;

    @include m.sp {
      margin-right: 12px;
    }
  }

  .c-text02 {
    &+.c-text02.text-bold {
      margin-top: 24px;

      @include m.sp {
        margin-top: 12px;
      }
    }
  }
}

.c-block04 {
  &__title {
    font-size: 4rem;
    line-height: 1;
    font-weight: 600;
    color: #000;
    text-align: center;
    margin: 82px 0 43px;
    @include m.yumincho();

    @include m.sp {
      font-size: 2.4rem;
      line-height: 1.5;
      margin: 25px 0 25px;
    }
  }

  // .c-block04__list .
  &__list {
    display: block;
    width: 100%;
    max-width: 555px;
    margin: 15px auto;

    @include m.sp {
      margin-bottom: 20px;
      margin: 5px auto;
    }

    @include m.pc {
      left: 2px;
      position: relative;
    }
  }

  // .c-block04__item .
  &__item {
    padding-left: 30px;
    margin: 12px 0;
    position: relative;
    font-weight: 600;
    display: inline-block;
    text-decoration-line: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 5px;
    letter-spacing: -0.8px;
    font-size: 2.1rem;

    @include m.sp {
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin: 8px 0;
      padding-left: 25px;
    }

    // .c-block04__item:before .
    &:before {
      top: -3px;
      left: 0px;
      width: 24px;
      height: 25px;
      content: "";
      background: url(p.$path + "images/common/icon-setting.png") no-repeat center/ cover;
      position: absolute;

      @include m.sp {
        width: 15px;
        height: 16px;
        top: 5px;
      }
    }
  }

  // .c-block04__content .
  &__content {
    max-width: 555px;
    margin: 52px auto 57px;
    position: relative;
    font-size: 2rem;
    line-height: 1;
    font-weight: 400;
    z-index: 1;
    @include m.yumincho();

    @include m.sp {
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin: 40px auto;
    }
  }

  &__text1 {
    font-size: 2rem;
    line-height: 45px;
    font-weight: 400;
    color: v.$color7;
    position: relative;
    z-index: 1;
    text-align: center;
    @include m.yumincho();

    @include m.sp {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  &__text2 {
    font-size: 3.2rem;
    line-height: 50px;
    font-weight: 600;
    color: v.$color7;
    position: relative;
    text-align: center;
    @include m.yumincho();

    @include m.sp {
      font-size: 2rem;
      line-height: 1.5;
    }
  }
}

.c-block05 {
  display: flex;
  align-items: flex-start;

  @include m.sp {
    flex-direction: column;
  }

  &__detail {
    width: 56.2%;

    @include m.sp {
      width: 100%;
      order: 1;
      margin-top: 20px;
    }
  }

  &__title {
    color: #558bb2;
    font-size: 3.2rem;
    font-weight: bold;
    @include m.yugothic;
    line-height: 40px;
    border-bottom: 3px solid #558bb2;
    padding-bottom: 12px;

    @include m.sp {
      font-size: 2rem;
      line-height: 26px;
      padding-bottom: 6px;
      border-bottom: 1px solid #558bb2;
    }

    &.u-font24 {

      @include m.sp {
        font-size: 2rem;
      }

      .u-font20 {
        font-size: 2rem;

        @include m.sp {
          font-size: 1.6rem;
        }
      }
    }
  }

  &__note1 {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 500;
    margin-top: 15px;
    display: block;

    @include m.sp {
      font-size: 1.2rem;
      margin-top: 10px;
    }
  }

  &__note2 {
    font-size: 1.6rem;
    line-height: 4rem;
    font-weight: bold;
    margin-top: 4px;
    display: block;

    @include m.sp {
      font-size: 1.2rem;
      line-height: 2.4rem;
    }
  }

  &__txt {
    margin-top: 24px;
    color: #000000;
    font-size: 2.4rem;
    line-height: 40px;
    font-weight: 500;
    @include m.yugothic;

    @include m.sp {
      margin-top: 14px;
      font-size: 1.6rem;
      line-height: 24px;
    }
  }

  &__txt1 {
    font-size: 2rem;
    line-height: 2.4rem;

    @include m.sp {
      font-size: 1.6rem;
    }
  }

  &__txt2 {
    font-size: 1.6rem;
    line-height: 2.4rem;
    display: block;

    @include m.sp {
      font-size: 1.2rem;
    }
  }

  &__list {
    margin-left: 50px;
    margin-top: 53px;
    flex: 1;

    @include m.sp {
      margin-left: 0;
      margin-top: 0;
      order: 0;
      width: 100%;
    }
  }

  &__item {
    margin-bottom: 30px;

    @include m.sp {
      margin-bottom: 15px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__img {
    img {
      width: 100%;
    }
  }

  &--no-img {
    .c-block05 {
      &__detail {
        width: 100%;
      }

      &__title {
        font-size: 3.2rem;

        @include m.sp {
          font-size: 2.4rem;
        }
      }

      &__txt {
        font-size: 1.6rem;
        line-height: 24px;

        @include m.sp {
          font-size: 1.3rem;
        }
      }
    }
  }

  &--reverse {
    .c-block05 {
      &__img {
        margin-top: 3px;

        @include m.sp {
          margin-top: 0;
        }
      }

      &__list {
        margin-left: 0;
        margin-top: 0;
        margin-right: 37px;

        @include m.sp {
          margin-right: 0;
          margin-top: 0;
        }
      }

      &__txt {
        font-weight: bold;
        margin-top: 10px;

        @include m.sp {
          margin-top: 5px;
        }
      }

      &__detail {
        width: 55.8%;

        @include m.sp {
          width: 100%;
        }
      }
    }
  }

  .c-btn04 {
    margin-top: 17px;

    @include m.sp {
      margin: 20px auto 0;
    }
  }
}

.c-block06 {
  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 59%;
    margin: 0 auto;
    margin-top: 58px;

    @include m.sp {
      margin-top: 29px;
      width: 100%;
    }
  }

  &__list {
    margin-top: 67px;

    @include m.sp {
      margin-top: 33px;
    }
  }

  &__item {
    margin-bottom: 109px;

    @include m.sp {
      margin-bottom: 53px;
    }

    &:first-child {
      .c-btn01 {
        &__text {
          &::after {
            margin-left: 10px;
            content: "";
            width: 13px;
            height: 13px;
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            transform: rotate(45deg);
            transition: 0.3s ease-in-out;
            margin-bottom: 1px;

            @include m.sp {
              width: 8px;
              height: 8px;
              margin-left: 7px;
              margin-bottom: 2px;
            }
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0;

      .c-btn01 {
        &__text {
          &::after {
            display: none;
          }
        }
      }
    }

    .c-text06 {
      margin-top: 26px;

      @include m.sp {
        margin-top: 13px;
      }
    }

    .c-btn01 {
      margin: 0 auto;
      margin-top: 58px;
      width: 480px;

      @include m.sp {
        margin-top: 30px;
        width: 100%;
        max-width: 300px;
      }

      &__link {
        padding: 20px 5px;
        line-height: 1.3;

        @include m.sp {
          padding: 23px 5px;
          line-height: 1.5;
        }
      }
    }
  }
}

.c-block07 {
  font-size: 1.6rem;

  @include m.sp {
    font-size: 1.4rem;
  }

  &__content {
    counter-reset: counterblock07;
  }

  &__item {
    font-weight: 500;

    &:not(:last-of-type) {
      margin-bottom: 29px;

      @include m.sp {
        margin-bottom: 20px;
      }
    }
  }

  &__txt1 {
    font-weight: bold;
    position: relative;
    margin-bottom: 14px;

    @include m.sp {
      margin-bottom: 5px;
    }

    &:before {
      counter-increment: counterblock07;
      content: counter(counterblock07) ".";
      margin-right: 3px;
    }
  }

  &__list1 {
    border: 3px solid #558bb2;
    border-radius: 16px;
    color: #558bb2;
    padding: 22px 25px 24px 32px;
    max-width: 818px;
    margin: 0 auto;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: calc(27 / 16);

    @include m.sp {
      font-size: 1.4rem;
      padding: 10px 15px;
      border-radius: 10px;
    }

    &__row {
      position: relative;
      padding-left: 15px;

      &:before {
        content: "・";
        position: absolute;
        left: 0;
      }
    }
  }

  &__list2 {
    line-height: calc(26 / 16);
    margin-bottom: 41px;
    font-weight: 500;
    padding-left: 20px;

    @include m.sp {
      margin-bottom: 20px;
      padding-left: 10px;
    }

    &__row {
      position: relative;
      padding-left: 20px;

      &:before {
        content: "◆";
        position: absolute;
        color: #558bb2;
        left: 0;
      }
    }
  }

  &__list3 {
    gap: 70px 100px;
    display: flex;
    flex-wrap: wrap;

    @include m.sp {
      gap: 15px 0;
    }

    &__item {
      max-width: 399px;
      width: 100%;
    }

    &__ttl {
      font-size: 2.4rem;
      font-weight: bold;
      line-height: calc(32 / 24);
      position: relative;
      padding-left: 20px;
      margin-bottom: 14px;

      @include m.sp {
        font-size: 1.6rem;
        margin-bottom: 5px;
      }

      &:before {
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        background: #558bb2;
        left: 0;
        top: 8px;

        @include m.sp {
          width: 12px;
          height: 12px;
          top: 4px;
        }
      }
    }

    &__txt2 {
      font-weight: 500;

      @include m.sp {
        font-size: 1.4rem;
      }
    }
  }

  &__img1 {
    margin: 2px 0 30px;

		@include m.sp{
			margin-bottom: 20px;
		}

    img {
      margin: 0 auto;
    }
  }

  .c-title14 {
    margin-bottom: 26px;

    @include m.sp {
      margin-bottom: 20px;
    }

    &.u-mgb50 {
      margin-bottom: 50px;

      @include m.sp {
        margin-bottom: 15px;
      }
    }
  }
}
