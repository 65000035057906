@use "../settings/variable" as v;
@use "../settings/mixin" as m;
@use "../settings/path" as p;

.c-list01 {
  $root: &;
  display: flex;
  flex-wrap: wrap;
  gap: 0 45px;
  @include m.sp {
    gap: 0;
  }

  &__item {
    width: calc(50% - (45px / 2));
    @include m.sp {
      width: 100%;
      font-size: v.vw(8);
      line-height: 1.2;
      padding: v.vw(3) 0;

      &:nth-child(odd) {
        order: 1;
      }

      &:nth-child(even) {
        order: 2;
      }
    }

    i.ic-arrow02 {
      flex-shrink: 0;
      margin-left: -9px;
      position: relative;
      right: -15px;
      @include m.sp {
        width: v.vw(5);
        height: v.vw(6);
      }
    }
  }

  &__text {
    display: inline-block;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 2;
    @include m.sp {
      font-size: v.vw(8);
      line-height: 1.2;
    }
  }
  &.style1 {
    #{$root}__item {
      width: 100%;
    }
  }
}

.c-list02 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  @include m.sp {
    margin: 0 -5px;
  }

  &__item {
    width: 50%;
    padding: 0 15px;
    margin-bottom: 17px;
    @include m.sp {
      padding: 0 5px;
      margin-bottom: 15px;
    }
  }

  &__video {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    height: 0;
    margin-bottom: 15px;
    @include m.sp {
      margin-bottom: 7px;
    }

    iframe {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__text1 {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.6;
    @include m.sp {
      font-size: 1.2rem;
    }
  }

  &__text2 {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 1.333;
    @include m.sp {
      font-size: 1.3rem;
    }
  }

  &__text3 {
    font-size: 1.6rem;
    line-height: 2;
    @include m.sp {
      font-size: 1rem;
    }
  }
}

.c-list03 {
  li {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 500;
    position: relative;
    padding-left: 24px;
    @include m.sp {
      font-size: 1.4rem;
      line-height: 1.5;
    }
    &::before {
      content: "●";
      color: #558bb2;
      font-weight: bold;
      position: absolute;
      top: 1px;
      left: 0;
    }
  }
}

.c-list04 {
  list-style: none;
  counter-reset: my-awesome-counter;
  &__item {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 500;
    counter-increment: my-awesome-counter;
    position: relative;
    padding-left: 24px;
    &::before {
      content: counter(my-awesome-counter) ".";
      color: #000000;
      font-weight: bold;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &__ttl {
    font-weight: bold;
  }
}

.c-list05 {
  counter-reset: count;

  &__item {
    counter-increment: count;

    // .c-list05__item:not(:last-child) .
    &:not(:last-child) {
      margin-bottom: 60px;

      @include m.sp {
        margin-bottom: 30px;
      }
    }
  }

  &__sub1 {
    counter-reset: countsub1;

    &__item {
      counter-increment: countsub1;

      // .c-list05__sub1__item:not(:last-child) .
      &:not(:last-child) {
        margin-bottom: 25px;

        @include m.sp {
          margin-bottom: 20px;
        }
      }
    }

    .c-text02 {
      display: flex;

      // .c-list05__sub1 .c-text02:before .
      &:before {
        letter-spacing: 4px;
        margin-right: -3px;
        content: "（" counter(countsub1) "）";
        min-width: max-content;
      }
    }
  }

  // .c-list05__sub2 .
  &__sub2 {
    margin-top: 5px;
    padding-left: 40px;
    counter-reset: countsub2;

    // .c-list05__sub2__item .
    &__item {
      font-size: 1.6rem;
      line-height: 2.4rem;
      counter-increment: countsub2;
      display: flex;
      font-weight: 500;

      @include m.sp {
        font-size: 1.6rem;
        line-height: 2.4rem;
      }

      // .c-list05__sub2__item:before .
      &:before {
        margin-right: 14px;
        content: counter(countsub2, lower-alpha) ".";
        min-width: max-content;
      }
    }
  }

  &__text {
    padding-left: 30px;

    @include m.sp {
      padding-left: 20px;
    }
  }

  // .c-list05__title .
  &__title {
    margin-bottom: 21px;
    font-weight: 700;
    color: #000;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    font-size: 2.4rem;
    line-height: 3.9rem;

    @include m.sp {
      padding-left: 30px;
      margin-bottom: 12px;
      position: relative;
      font-size: 1.8rem;
      line-height: 2.8rem;
    }

    // .c-list05__title:before .
    &:before {
      margin-right: 7px;
      content: counter(count) ".";
      min-width: max-content;

      @include m.sp {
        top: 0;
        left: 0;
        margin-right: 0;
        position: absolute;
      }
    }

    // .c-list05__title .is-small .
    .is-small {
      padding-bottom: 6px;
      line-height: 2.1rem;
      font-size: 1.6rem;
      @include m.sp {
        padding-bottom: 0;
      }
    }
  }

  .c-text02 {
    // .c-list05 .c-text02 + .c-text02 .
    & + .c-text02 {
      margin-top: 24px;
    }
  }

  // .c-list05 .c-text03 .
  .c-text03 {
    margin-top: 5px;
    padding-left: 37px;
  }
}

.c-list06 {
  counter-reset: my-awesome-counter;
  &__item {
    counter-increment: my-awesome-counter;
    &:not(:last-child) {
      margin-bottom: 44px;
      @include m.sp {
        margin-bottom: 20px;
      }
    }
    &:nth-child(n + 10) {
      .c-list06__icon {
        @include m.sp {
          padding-left: 38px;
        }
      }
    }
  }
  &__ttlmain {
    font-size: 2.4rem;
    line-height: 3.5rem;
    font-weight: bold;
    color: #558bb2;
    display: flex;
    @include m.sp {
      font-size: 2rem;
      line-height: 3rem;
    }
    &::before {
      margin-right: 7px;
      min-width: max-content;
      content: counter(my-awesome-counter) ". ";
    }
  }
  &__icon {
    flex: 1;
    margin-left: 20px;
    @include m.sp {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-left: 0;
      margin-top: 5px;
      padding-left: 25px;
    }
    img {
      display: inline-block;
      width: 45px;
      @include m.sp {
        width: 38px;
      }
      &:not(:last-child) {
        margin-right: 8px;
        @include m.sp {
          margin-right: 2%;
        }
      }
    }
  }
  &__ttl {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
    @include m.sp {
      display: block;
    }
  }
  &__text {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 500;
  }
  .c-text02 {
    text-align: justify;
  }
}

.c-list07 {
  margin: 0 -12px;
  display: flex;
  flex-wrap: wrap;
  @include m.sp {
    margin: 0;
  }

  &__inner {
    position: relative;
    cursor: pointer;

    &:before {
      content: "";
      background-color: rgba($color: v.$color1, $alpha: 0.5);
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      mix-blend-mode: multiply;
    }
  }

  // .c-list07__img .
  &__img {
    position: relative;
    z-index: 0;
    overflow: hidden;
    img {
      @include m.sp {
        width: 100%;
      }
    }
  }

  &__item {
    width: 50%;
    padding: 0 12px 24px;
    @include m.sp {
      width: 100%;
      padding: 0 0 20px;
    }
  }

  // .c-list07__content .
  &__content {
    padding-top: 20px;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    top: 0;
    left: 0;

    &.is-style1 {
      padding: 0 0 4px;

      .c-list07 {
        // .c-list07__content.is-style1 .c-list07__text .
        &__text {
          margin-top: 26px;

          @include m.sp {
            margin-top: 10px;
          }
        }
      }
    }
  }

  // .c-list07__title .
  &__title {
    font-weight: 700;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3.6rem;
    line-height: 1.5;
    width: 100%;

    @include m.sp {
      font-size: 2rem;
    }

    // .c-list07__title.is-small .
    &.is-small {
      font-size: 3.2rem;

      @include m.sp {
        font-size: 2rem;
      }
    }
    > span {
      font-size: 2.4rem;
      @include m.sp {
        font-size: 1.6rem;
      }
    }
  }

  &__text {
    margin-top: 4px;
    color: #fff;
    display: block;
    text-align: center;
    font-weight: 700;
    font-size: 2rem;
    line-height: 1.5;
    width: 100%;
    @include m.sp {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    &.is-small {
      font-size: 1.2rem;
    }
  }
}

.c-list08 {
  &__item {
    width: 100%;
    border: 1px solid v.$color1;
    background-color: #fff;

    @include m.pc {
      display: flex;
    }
    @include m.sp {
      overflow: hidden;
    }

    // .c-list08__item:not(:last-child) .
    &:not(:last-child) {
      margin-bottom: 29px;
      position: relative;

      &:after {
        bottom: -25px;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent;
        border-width: 19px 29px 0 29px;
        border-top-color: v.$color1;
      }
    }
  }

  // .c-list08__texts .
  &__texts {
    padding: 9px 15px 7px 26px;
    width: calc(100% - 189px);

    @include m.sp {
      padding: 30px 10px 10px;
      width: 100%;
    }
  }

  &__text {
    font-size: 2rem;
    line-height: 2.4rem;
    color: #000;
    font-weight: 500;
    @include m.sp {
      font-size: 1.8rem;
    }

    // .c-list08__text.is-small .
    &.is-small {
      margin-top: 2px;
      font-size: 1.6rem;
    }
  }

  // .c-list08__title .
  &__title {
    padding: 18px 20px 8px 7px;
    width: 179px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: v.$color1 url(p.$path + "images/common/bg-list8.jpg") no-repeat center right / cover;

    @include m.sp {
      padding: 10px 10px 0;
      width: 100%;
      background: v.$color1;
      position: relative;
    }

    &:after {
      @include m.sp {
        content: "";
      }
      bottom: -14px;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      height: 0;
      border-style: solid;
      border-color: transparent;
      border-width: 15px calc(50vw) 0 calc(50vw);
      border-top-color: v.$color1;
    }

    &__text {
      font-size: 2.4rem;
      line-height: 1.5;
      color: #fff;
      font-weight: 700;

      @include m.sp {
        font-size: 2rem;
      }
    }
  }
}

.c-list09 {
  &__item {
    display: flex;
    flex-wrap: wrap;
    &:first-child {
      .c-list09 {
        &__detail {
          margin-top: 79px;
          @include m.sp {
            margin-top: 40px;
          }
        }
        &__list1 {
          margin-top: 0;
          margin-bottom: 0;
          padding-bottom: 63.6px;
        }
      }
    }
    &:last-child {
      padding-bottom: 0;
      .c-list09 {
        &__detail {
          padding-bottom: 0;
          &::after {
            display: none;
          }
        }
        &__list1 {
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  &__detail {
    width: 50.6%;
    position: relative;
    padding-bottom: 170.6px;
    margin-top: 0;
    @include m.sp {
      padding-bottom: 85px;
      width: 51%;
    }
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      right: 0;
      width: 10px;
      height: 100%;
      background: #256695;
      top: 0;
      @include m.sp {
        width: 5px;
      }
    }
  }

  &__title {
    @include m.yugothic;
    font-size: 4.5rem;
    line-height: 50px;
    font-weight: bold;
    color: #256695;
    border-bottom: 5px solid #dbdbdb;
    padding-bottom: 9px;
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin-right: 41px;
    margin-bottom: 45px;
    margin-top: -45px;
    @include m.sp {
      font-size: 2rem;
      line-height: 25px;
      margin-right: 15px;
      margin-bottom: 23px;
      margin-top: -23px;
      padding-bottom: 4px;
      border-bottom: 2px solid #dbdbdb;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &::after {
      content: "";
      display: inline-block;
      width: 33px;
      height: 33px;
      background: #256695;
      border-radius: 50%;
      margin-right: -12px;
      margin-left: 12px;
      margin-top: -2px;
      @include m.sp {
        width: 15px;
        height: 15px;
        margin-right: -5px;
        margin-left: 5px;
      }
    }
  }

  &__txt1 {
    margin-top: -29px;
    font-size: 2.4rem;
    line-height: 32px;
    font-weight: bold;
    @include m.yugothic;
    color: #256695;
    padding-right: 74px;
    @include m.sp {
      font-size: 1.6rem;
      line-height: 24px;
      padding-right: 30px;
      margin-top: -14px;
    }
  }

  &__img {
    img {
      width: 100%;
    }
  }

  &__list1 {
    margin-left: 133px;
    width: 26.4%;
    margin-top: -65px;
    margin-bottom: 65px;
    padding-bottom: 54.6px;
    @include m.sp {
      margin-left: 25px;
      width: 40%;
      margin-top: -30px;
      margin-bottom: 30px;
      padding-bottom: 35px;
    }
  }

  &__item1 {
    margin-bottom: 78px;
    @include m.sp {
      margin-bottom: 38px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__txt2 {
    color: #000000;
    font-size: 2.4rem;
    line-height: 24px;
    font-weight: bold;
    @include m.yugothic;
    display: flex;
    justify-content: center;
    margin-top: 16px;
    @include m.sp {
      font-size: 1.4rem;
      margin-top: 8px;
    }
  }
}

.c-list10 {
  > .l-cont2 {
    @include m.pc {
      max-width: 834px;
      padding: 0 34px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    @include m.pc {
      margin: 0 -34px;
    }

    @include m.sp {
      justify-content: space-between;
    }
  }

  // .c-list10__item .
  &__item {
    width: 33.3333%;
    margin-bottom: 61.3px;

    @include m.pc {
      padding: 0 34px;
    }

    @include m.sp {
      margin-bottom: 20px;
      width: calc(50% - 10px);
    }
  }

  // .c-list10__img .
  &__img {
    padding-top: 83.2%;
    width: 100%;
    height: 0;
    position: relative;
    overflow: hidden;

    img {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }
  }

  // .c-list10__text .
  &__text {
    margin-top: 12px;
    font-size: 2rem;
    line-height: 1.4;
    font-weight: 700;
    color: #000;
    display: inline-block;
    white-space: nowrap;

    @include m.sp {
      font-size: 1.6rem;
      white-space: unset;
    }

    & .is-small {
      font-size: 1.4rem;
    }

    // .c-list10__text:after .
    &:after {
      top: 1px;
      border-width: 7px 0 7px 13px;
      margin-left: 7px;
      display: inline-block;
      content: "";
      border-style: solid;
      border-color: transparent;
      border-left-color: v.$color1;
      position: relative;

      @include m.sp {
        margin-left: 5px;
        border-width: 5px 0 5px 5px;
      }
    }
  }
}

.c-list11{
	&__item{
		position: relative;
		padding-left: 15px;

		@include m.sp{
			padding-left: 12px;
		}

		&::before{
			position: absolute;
			content: '・';
			color: #000;
			top: 0;
			bottom: 0;
			left: 0;
			margin: auto;
		}
	}
}
